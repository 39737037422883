/* eslint-disable max-len */
import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { GenericConfirmationModalComponent } from '../../container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericSwitchConfirmationModalComponent } from '../../container/modal/generic-switch-confirmation-modal/generic-switch-confirmation-modal.component';
import { GenericErrorModalComponent } from '../../container/modal/generic-error-modal/generic-error-modal.component';
import { InvalidShipmentOriginModalComponent } from '../../container/modal/invalid-shipment-origin-modal/invalid-shipment-origin-modal.component';
import { AuthorizationService } from '../../core/authorization.service';
import { isNilty } from '../../core/utils.service';
import { ENTITIES } from '../../entities';
import { stringToDate } from '../../models/date-converter';
import { ShipmentFilters } from '../../models/filters/shipment-filters-model';
import { FulfilmentKpi } from '../../models/fulfilment-kpi';
import { Permissions } from '../../models/permission-model';
import { Shipment, ShipmentStatus } from '../../models/shipment-model';
import { ShipmentStatusEnum } from '../../models/status-enum';
import { Status } from '../../models/status-model';
import { CommonsService } from '../commons.service';
import { ImportExportService } from '../import-export.service';
import { LotsService } from '../lots.service';
import { ShipmentsService } from '../shipments.service';
import { ManualInvoiceModalComponent, ManualInvoiceModalData } from './manual-invoice-modal/manual-invoice-modal.component';
import { SendDocumentsModalComponent } from './send-documents-modal/send-documents-modal.component';

@Component({
  selector: 'app-single-shipment-detail',
  templateUrl: './single-shipment-detail.component.html',
  styleUrls: ['./single-shipment-detail.component.css'],
})
export class SingleShipmentDetailComponent implements OnInit {
  @ViewChild('getFile', { static: false }) getFile: ElementRef;

  @ViewChild('cartonsMatGroup', { static: false }) matTabGroup: MatTabGroup;

  loadShipmentItems = new Subject<ShipmentFilters>();
  exportSubject = new Subject<string>();
  splitShipmentSubject = new Subject<ShipmentFilters>();

  currentFileUpload: File;

  shipmentId: number;

  shipment: Shipment = new Shipment();

  isNotInLot = false;

  isSendableToBridget = false;
  isDeletableFromBridget = false;
  isCancelableAsn = false;
  canGenerateManualInvoice = false;

  isShipped = false;

  filters = new ShipmentFilters();

  shipmentStatus: ShipmentStatus[];

  shipmentPaymentStatus: Status[];

  kpiData: Observable<FulfilmentKpi>;

  hasShipmentManagement: Observable<boolean>;
  hasShipmentCreation: Observable<boolean>;
  hasCancelShipment: Observable<boolean>;
  hasChangeShipmentPaymentStatus: Observable<boolean>;
  hasInvoiceManagement: Observable<boolean>;
  hasResendToNav: Observable<boolean>;

  hasPackingListImport: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private shipmentsService: ShipmentsService,
    private lotsService: LotsService,
    private importExportService: ImportExportService,
    private location: Location,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
    private router: Router,
    private commonsService: CommonsService
  ) {}

  ngOnInit() {
    this.isNotInLot = this.lotsService.currentLotId === undefined;
    this.kpiData = this.shipmentsService.kpiData;
    this.route.params.subscribe((param: Params) => {
      this.shipmentId = +param['id'];
      this.shipmentsService.getShipment(this.shipmentId).subscribe((ship: Shipment) => {
        this.shipment = ship;
        this.isSendableToBridget = this.computeIsSendableToBridget();
        this.isDeletableFromBridget = this.computeIsDeletableFromBridget();
        this.isCancelableAsn = this.computeIsCancelableASN();
        this.canGenerateManualInvoice = this.computeCanGenrateManualInvoice();
        this.isShipped = this.computeIsShipped();
        this.filters = new ShipmentFilters();
        this.filters.shipment = this.shipment.shipmentCode;
        this.loadShipmentItems.next(this.filters);
        this.updateStatus();
      });
    });

    this.shipmentsService.getShipmentPaymentStatus().subscribe((status: Status[]) => {
      this.shipmentPaymentStatus = status;
    });

    this.hasShipmentManagement = this.authorizationService.hasPermission(Permissions.ShipmentManagement);
    this.hasCancelShipment = this.authorizationService.hasPermission(Permissions.CancelShipments);
    this.hasShipmentCreation = this.authorizationService.hasPermission(Permissions.ShipmentCreation);
    this.hasPackingListImport = this.authorizationService.hasPermission(Permissions.PackingListImport);
    this.hasChangeShipmentPaymentStatus = this.authorizationService.hasPermission(Permissions.ChangeShipmentPaymentStatus);
    this.hasInvoiceManagement = this.authorizationService.hasPermission(Permissions.InvoiceManagement);
    this.hasResendToNav = this.authorizationService.hasPermission(Permissions.ResendToNav);
  }

  updateStatus() {
    this.commonsService.getShipmentStatuses().subscribe((r) => {
      if (!this.shipment.transitWarehouse) {
        this.shipmentStatus = r.filter((st) => st.code !== ShipmentStatusEnum.INCOMING);
      } else {
        this.shipmentStatus = r;
      }
    });
  }

  applyFilters(filters: ShipmentFilters) {
    this.filters = filters;
    this.filters.shipment = this.shipment.shipmentCode;
    this.loadShipmentItems.next(this.filters);
  }

  goBack() {
    this.location.back();
  }

  generateAsnMarginDialog() {
    if (this.shipment.totalShippedValue < (this.shipment.origin.supplier.minimalValue ?? 0)) {
      const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
        width: '350px',
        data: "Order value is below the supplier's minimal value.",
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.generateAsnDialog();
        }
      });
    } else {
      this.generateAsnDialog();
    }
  }

  generateAsnDialog() {
    if (!this.shipment.asnCode && stringToDate(this.shipment.earliestDeliveryDate) > new Date()) {
      const dialogDateRef = this.dialog.open(GenericConfirmationModalComponent, {
        width: '350px',
        data: 'Earliest delivery date is after the asn generation date.',
      });

      dialogDateRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.generateAsn();
        } else {
          console.log('Request canceled.');
        }
      });
    } else {
      this.generateAsn();
    }
  }

  generateAsn() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: 'Generating ASN for Shipment ' + this.shipment.shipmentCode + '.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.shipment.paymentStatus.code !== 'DONE') {
          const dialogPaymentRef = this.dialog.open(GenericConfirmationModalComponent, {
            width: '350px',
            data: 'Payment status is: ' + this.shipment.paymentStatus.description + '.',
          });

          dialogPaymentRef.afterClosed().subscribe((resultPayment: boolean) => {
            if (resultPayment) {
              this.generateAsnRequest();
            } else {
              console.log('Request canceled.');
            }
          });
        } else {
          this.generateAsnRequest();
        }
      } else {
        console.log('Request canceled.');
      }
    });
  }

  generateAsnRequest() {
    this.shipmentsService.generateAsn(this.shipmentId).subscribe(() => {
      this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
    });
  }

  generateDdt() {
    this.importExportService.generateDdt(this.shipmentId, 'excel').subscribe(() => {});
  }

  exportDdt(format: string) {
    this.importExportService.exportDdt(this.shipmentId, format).subscribe(() => {});
  }

  importFile() {
    this.getFile.nativeElement.click();
  }

  fileChanged(event) {
    this.currentFileUpload = event.target.files[0];
    this.snackBar.open('Uploading', '')._dismissAfter(3000);
    this.importExportService.uploadFile(this.currentFileUpload, ENTITIES.PACKING_LIST, null, this.shipmentId).subscribe(() => {});
  }

  reload() {
    this.shipmentsService.getShipment(this.shipmentId).subscribe((ship: Shipment) => {
      this.shipment = ship;
      this.isSendableToBridget = this.computeIsSendableToBridget();
      this.isDeletableFromBridget = this.computeIsDeletableFromBridget();
      this.isCancelableAsn = this.computeIsCancelableASN();
      this.canGenerateManualInvoice = this.computeCanGenrateManualInvoice();
      this.isShipped = this.computeIsShipped();
      this.updateStatus();
    });
  }

  refresh() {
    this.reload();
    this.loadShipmentItems.next(this.filters);
  }

  changeStatus(status: string) {
    const message =
      status === ShipmentStatusEnum.SHIPPED
        ? 'This Shipment will be sent to NAV and the POAs will be sent to Amazon.'
        : status === ShipmentStatusEnum.CANCELED
        ? 'This operation cannot be undone: the Shipment will be canceled and the POAs will be sent to Amazon.'
        : '';

    const canceled = status === ShipmentStatusEnum.CANCELED;
    const hasTransit = this.shipment?.isDirect === false && this.shipment?.logisticPartner === 'TWS';

    if (!isNilty(message)) {
      const dialogRef = this.dialog.open(
        canceled && hasTransit ? GenericSwitchConfirmationModalComponent : GenericConfirmationModalComponent,
        {
          maxWidth: '550px',
          data:
            canceled && hasTransit ? { message, switchQuestion: 'Should the incoming order still be considered as outstanding?' } : message,
        }
      );

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const switchStatus = canceled && hasTransit ? result.switchStatus : null;
          this.shipmentsService.changeShipmentsStatus(status, this.shipmentId, switchStatus).subscribe(() => {
            this.reload();
          });
        }
      });
    } else {
      this.shipmentsService.changeShipmentsStatus(status, this.shipmentId).subscribe(() => {
        this.reload();
      });
    }
  }

  changePaymentStatus(status: string) {
    this.shipmentsService.changeShipmentsPaymentStatus(status, [this.shipmentId]).subscribe(() => {
      this.reload();
    });
  }

  shipAll() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: 'Confirmed quantities will be marked as shipped.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.shipmentsService.shipAll(this.shipmentId).subscribe(() => {
          this.refresh();
        });
      }
    });
  }

  resetShippedQuantities() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: 'Shipped quantities will be set to zero and packing properties will be reset.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.shipmentsService.resetShippedQuantities(this.shipmentId).subscribe(() => {});
      }
    });
  }

  export(format: string) {
    this.exportSubject.next(format);
  }

  splitShipment() {
    this.splitShipmentSubject.next(this.filters);
  }

  exportCartonItems(format: string) {
    this.importExportService.exportCartonItems(this.shipmentId, format).subscribe();
  }

  exportCartons(format: string) {
    this.importExportService.exportCartons(this.shipmentId, format).subscribe();
  }

  printASNBarcode() {
    this.shipmentsService.printASNBarcode(this.shipmentId).subscribe((data) => {
      const barcodeBlob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(barcodeBlob);
      const anchor = document.createElement('a');
      anchor.download = this.shipment.orderId + '_' + this.shipment.origin.code + '_' + this.shipment.destination.code + '_ASN_Barcode.pdf';
      anchor.href = url;
      anchor.click();
    });
  }

  printPdf() {
    if (!isNilty(this.shipment.ddtPdfS3Url)) {
      window.open(this.shipment.ddtPdfS3Url, '_blank');
    }
  }

  printXlsx() {
    if (!isNilty(this.shipment.ddtXlsxS3Url)) {
      window.open(this.shipment.ddtXlsxS3Url, '_blank');
    }
  }

  cancelAsn() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '500px',
      data:
        // eslint-disable-next-line
        'Shipped quantities will be reset to zero and the ASN will be canceled. All packing list informations will be deleted. The shipment status will be reset to New.',
    });

    dialogRef.afterClosed().subscribe((resp: boolean) => {
      if (resp) {
        this.shipmentsService.cancelASN(this.shipmentId).subscribe(() => {
          this.loadShipmentItems.next(this.filters);
        });
      }
    });
  }

  singleCarton() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: `Generating single carton for Shipment ${this.shipment.shipmentCode}.`,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.shipmentsService.singleCarton(this.shipmentId).subscribe((response: string) => {
          this.refresh();
        });
      } else {
        console.log('Request canceled.');
      }
    });
  }

  sendToBridget() {
    if (!this.hasValidOrigin()) {
      this.dialog.open(InvalidShipmentOriginModalComponent, {
        width: '350px',
        data: {
          stockLocationId: this.shipment.origin.id,
        },
      });
      return;
    }

    if (!this.isValidTransitShipment()) {
      this.dialog.open(GenericErrorModalComponent, {
        width: '350px',
        data: 'This is a transit shipment: please select a transit warehouse.',
      });
      return;
    }

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: `Shipping Shipment ${this.shipment.shipmentCode} via Bridget.`,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.shipmentsService.sendToBridget(this.shipmentId).subscribe((response: string) => {
          this.refresh();
        });
      }
    });
  }

  deleteFromBridget() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: `Delete Shipment ${this.shipment.shipmentCode} from Bridget.`,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.shipmentsService.deleteFromBridget(this.shipmentId).subscribe((response: string) => {
          this.refresh();
        });
      }
    });
  }

  downloadCartonsExcel() {
    if (!isNilty(this.shipment.cartonsXlsxUrl)) {
      window.open(this.shipment.cartonsXlsxUrl, '_blank');
    }
  }

  downloadCartonsCsv() {
    if (!isNilty(this.shipment.cartonsCsvUrl)) {
      window.open(this.shipment.cartonsCsvUrl, '_blank');
    }
  }

  sendToSupplier() {
    const send = () => {
      if (isNilty(this.shipment.origin.supplier.shipmentDocumentationReceivers)) {
        this.dialog
          .open(GenericConfirmationModalComponent, {
            data: {
              message: `Supplier ${this.shipment.origin.supplier.code} has no configured receivers for Shipment Documentation`,
              question: 'Would you like to configure it now?',
            },
          })
          .afterClosed()
          .subscribe((resp: boolean) => {
            if (resp) {
              this.router.navigate(['inventory', 'suppliers', 'edit', this.shipment.origin.supplier.id]);
            }
          });
      } else {
        this.dialog
          .open(SendDocumentsModalComponent, { data: this.shipment, disableClose: true })
          .afterClosed()
          .subscribe((resp: boolean) => {
            if (resp) {
              this.snackBar.open(`The selected documents will be sent to ${this.shipment.origin.supplier.code}`, '')._dismissAfter(3000);
            }
          });
      }
    };

    if (this.shipment.documentsSentToSupplier === true) {
      this.dialog
        .open(GenericConfirmationModalComponent, {
          data: {
            message: 'This shipment has already been sent',
            question: 'Would you like to resend it now?',
          },
        })
        .afterClosed()
        .subscribe((resp: boolean) => {
          if (resp) {
            send();
          }
        });
    } else {
      send();
    }
  }

  downloadPackingList() {
    if (!isNilty(this.shipment.packingListUrl)) {
      window.open(this.shipment.packingListUrl, '_blank');
    }
  }

  setCategory() {
    this.shipmentsService.setCategory(this.shipmentId).subscribe(() => {
      this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
      this.refresh();
    });
  }

  showCreateUkIntercoInvoice(): boolean {
    return (
      this.shipment.status?.code === ShipmentStatusEnum.DDT_CREATED &&
      isNilty(this.shipment.trackingNumber) &&
      this.shipment.ukIntercoInvoice &&
      this.shipment.ukIntercoInvoiceStatus.code === 'PENDING'
    );
  }

  createUkIntercoInvoice() {
    this.shipmentsService.createUkIntercoInvoice(this.shipmentId).subscribe(() => {
      this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
    });
  }

  exportIntercoInvoice() {
    this.importExportService.exportIntercoInvoice(this.shipment.ukIntercoInvoiceNumber).subscribe((resp) => console.log(resp));
  }

  exportExportDeclaration() {
    this.importExportService.exportExportDeclaration(this.shipment.ukIntercoInvoiceNumber).subscribe((resp) => console.log(resp));
  }

  private hasValidOrigin() {
    return (
      this.isValidTransitShipment() ||
      (!isNilty(this.shipment.origin.address) &&
        !isNilty(this.shipment.origin.city) &&
        !isNilty(this.shipment.origin.province) &&
        !isNilty(this.shipment.origin.postalCode) &&
        !isNilty(this.shipment.origin.countryCode))
    );
  }

  private isValidTransitShipment() {
    return (
      (this.shipment.origin != null && this.shipment.origin.supplier != null && this.shipment.origin.supplier.shipsToAmazon) ||
      !isNilty(this.shipment.transitWarehouse)
    );
  }

  private computeIsSendableToBridget() {
    return (
      (this.shipment.status.code === ShipmentStatusEnum.READY ||
        this.shipment.status.code === ShipmentStatusEnum.ASN_CREATED ||
        this.shipment.status.code === ShipmentStatusEnum.DDT_CREATED) &&
      !this.shipment.statusHandledByBridget
    );
  }

  private computeIsCancelableASN() {
    return (
      (this.shipment.status.code === ShipmentStatusEnum.ASN_CREATED || this.shipment.status.code === ShipmentStatusEnum.DDT_CREATED) &&
      !isNilty(this.shipment.asnCode) &&
      !this.shipment.statusHandledByBridget
    );
  }

  private computeCanGenrateManualInvoice() {
    return (
      (this.shipment.status.code === ShipmentStatusEnum.SHIPPED || this.shipment.status.code === ShipmentStatusEnum.DELIVERED) &&
      isNilty(this.shipment.invoice)
    );
  }

  private computeIsDeletableFromBridget() {
    return (
      (this.shipment.status.code === ShipmentStatusEnum.READY ||
        this.shipment.status.code === ShipmentStatusEnum.ASN_CREATED ||
        this.shipment.status.code === ShipmentStatusEnum.DDT_CREATED ||
        this.shipment.status.code === ShipmentStatusEnum.SHIPPED ||
        this.shipment.status.code === ShipmentStatusEnum.TRANSIT ||
        this.shipment.status.code === ShipmentStatusEnum.PARTIALLY_DELIVERED) &&
      this.shipment.statusHandledByBridget
    );
  }

  private computeIsShipped() {
    return (
      this.shipment.status.code === ShipmentStatusEnum.SHIPPED ||
      this.shipment.status.code === ShipmentStatusEnum.TRANSIT ||
      this.shipment.status.code === ShipmentStatusEnum.PARTIALLY_DELIVERED ||
      this.shipment.status.code === ShipmentStatusEnum.DELIVERED ||
      this.shipment.status.code === ShipmentStatusEnum.CANCELED ||
      this.shipment.statusHandledByBridget
    );
  }

  downloadSSCCBarcodes() {
    this.shipmentsService.printAllSSCCBarcode(this.shipmentId).subscribe((data) => {
      const barcodeBlob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(barcodeBlob);
      const anchor = document.createElement('a');
      anchor.download =
        this.shipment.orderId + '_' + this.shipment.origin.code + '_' + this.shipment.destination.code + '_SSCC_Barcodes.pdf';
      anchor.href = url;
      anchor.click();
    });
  }

  downloadLQLabels() {
    this.shipmentsService.printLQLabels(this.shipmentId).subscribe((data) => {
      const barcodeBlob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(barcodeBlob);
      const anchor = document.createElement('a');
      anchor.download = this.shipment.orderId + '_' + this.shipment.origin.code + '_' + this.shipment.destination.code + '_LQ_Labels.pdf';
      anchor.href = url;
      anchor.click();
    });
  }

  downloadLabels() {
    window.open(this.shipment.labelUrl, '_blank');
  }

  downloadSSCCAndLDVLabels() {
    this.shipmentsService.printAllSSCCAndLdvLabel(this.shipment.id).subscribe((data) => {
      const barcodeBlob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(barcodeBlob);
      const anchor = document.createElement('a');
      anchor.download =
        this.shipment.orderId +
        '_' +
        this.shipment.origin.code +
        '_' +
        this.shipment.destination.code +
        '_' +
        this.shipment.trackingNumber +
        '_SSCC_LDV_Labels.pdf';
      anchor.href = url;
      anchor.click();
    });
  }

  generateManualInvoice() {
    const dialogRef = this.dialog.open(ManualInvoiceModalComponent, {
      width: '350px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: ManualInvoiceModalData) => {
      if (!isNilty(result)) {
        this.shipmentsService.generateManualInvoice(this.shipment.id, result.invoiceNumber, result.invoiceDate).subscribe(() => {
          this.refresh();
        });
      }
    });
  }

  sendToNav() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: 'You are sending the shipment in ' + this.shipment.status.name + ' status to NAV.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.shipmentsService.sendToNav(this.shipmentId).subscribe(() => {
          this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
        });
      }
    });
  }

  resendToNav() {
    this.shipmentsService.resendToNav(this.shipmentId).subscribe(() => {
      this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
    });
  }

  sendOutbound() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: 'You are forcing the outbound to be sent.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.shipmentsService.sendOutbound(this.shipmentId).subscribe(() => {
          this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
        });
      }
    });
  }

  automaticManagementMarginDialog() {
    if (this.shipment.totalShippedValue < (this.shipment.origin.supplier.minimalValue ?? 0)) {
      const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
        width: '350px',
        data: "Order value is below the supplier's minimal value.",
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.automaticManagement();
        }
      });
    } else {
      this.automaticManagement();
    }
  }

  private automaticManagement() {
    this.shipmentsService.automaticManagement(this.shipmentId).subscribe(() => {
      this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
    });
  }
}
